import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { startCase } from 'lodash'
import dmas from '@/data/dmas'
import BigNumber from 'bignumber.js'
import MediaPlanItem from './MediaPlanItem'
import Model from './interface/Model'
import User from './User'
import Company from './Company'
import Api from './Api'
import Terms from './Terms'
import { tableFields } from './metadata/MediaPlanMetadata'

export default class MediaPlan extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'media_plan' as string | null,
      plural: 'media_plans' as string | null,
    },
  }

  public name: string = ''

  public type: string = 'default'

  public number: number = 0

  public agency_id: string | null = null

  public agency: Company | null = null

  public station_id: string | null = null

  public station: Company | null = null

  public advertiser_id: string = ''

  public advertiser: Company | null = null

  public contact_company: string = ''

  public contact_name: string = ''

  public contact_title: string = ''

  public contact_email: string = ''

  public sales_management_id: string | null = '92fb454e-7f5e-4fb5-a3d5-6b76756104ab'

  public sales_management: User | null = null

  public sales_rep_id: string | null = ''

  public sales_rep: User | null = null

  public account_manager_id: string | null = ''

  public account_manager: User | null = null

  public line_items: MediaPlanItem[] = [new MediaPlanItem()]

  public status: string = 'draft'

  public agency_commission: number = 0

  public created_at: string = ''

  public updated_at: string = ''

  public download_on_save: boolean = false

  public proposal_action: string = 'New'

  public salesforce_opportunity_id: string | null = null

  public terms_id: string | null = null

  public terms: string | null = null

  public metrics: any = {}

  public max_avails: number = 0

  public grps: number = 0

  public dynamic_rate_id: string | null = null

  public metadata: any = {
    view_columns: ['model', 'net_rate', 'net_cost'],
    agency: {
      id: '',
      name: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      address_line_4: '',
    },
    station: {
      call_letters: '',
    },
    header: {
      representative: '',
      advertiser_name: '',
      product_name: '',
      agency_estimate_code: '',
      start_date: '',
      end_date: '',
      station_order_number: '',
      station_trade_order_number: '',
      agency_advertiser_code: '',
      agency_product_code: '',
    },
    demo_targets: [
      {
        target: 'AD',
        age_low: '25',
        age_high: '54',
      },
    ],
    isci_required: false,
    cash_io: false,
    trade_io: false,
    billing_cycle: 'monthly',
    invoice_mode: 'platform',
    proposal: {
      messages: [],
    },
  }

  /**
   * UI Form Fields
   */
  public get has_trade_item() {
    return this.line_items.some(item => item.metadata.order_type === 'trade')
  }

  public get hasNotes() {
    return this.line_items.some(item => item.metadata.notes !== null && item.metadata.notes != '')
  }

  public get elegible_trade_spots() {
    let pointer = moment(this.start_at)
    let weeks = 0
    let spots = 0
    let month = pointer.month()
    while (pointer.isBefore(this.end_at)) {
      if (pointer.month() !== month) {
        if (weeks >= 1) spots++
        weeks = 0
        month = pointer.month()
      }
      pointer.add(1, 'week')
      if (pointer.month() === month) weeks++
    }

    if (weeks >= 1) spots++

    return spots
  }

  public get formSFOpportunityURL(): string {
    return `${process.env.VUE_APP_SALESFORCE_INSTANCE_URL}/lightning/r/Opportunity/${this.salesforce_opportunity_id}/view`
  }

  public get formName(): string {
    return this.name !== '' ? this.name : 'Untitled Media Plan'
  }

  public get formDemos() {
    return this.metadata.demo_targets
  }

  public set formDemos(demos: any) {
    this.metadata.demo_targets = demos
  }

  public get formAgencyCommission() {
    return this.agency_commission
  }

  public set formAgencyCommission(agency_commission: number) {
    this.agency_commission = Number(agency_commission)
    this.line_items.forEach(i => {
      if (i.agency_commission != this.agency_commission) {
        i.formAgencyCommission = this.agency_commission
      }
    })
  }

  public get formStationCallLetters(): string {
    return this.metadata.station.call_letters
  }

  public set formStationCallLetters(station: string) {
    if (station.length === 4 && station !== this.metadata.station.call_letters) {
      const code = station.slice(-2)
      const dma = dmas.find(dma => dma.media_ocean_station === code)
      if (dma) {
        this.line_items.forEach(item => {
          item.metadata.targetting.include.dmas = [dma.id]
        })
      }
    }
    this.metadata.station.call_letters = station
  }

  public get spots(): number[] {
    const ret = []
    const pointer = moment(this.start_at)
    const end = moment(this.end_at)

    while (pointer.isBefore(end)) {
      let sum = 0

      this.line_items.forEach(item => {
        if (pointer.isSameOrAfter(item.start_at) && pointer.isSameOrBefore(item.end_at)) {
          let week = pointer.diff(item.start_at, 'weeks')
          sum += item.metadata.spots[week] ?? 0
        }
      })

      ret.push(sum)

      pointer.add(1, 'week')
    }

    return ret
  }

  public get total_spots(): number {
    return this.spots.reduce(
      (carry, spot) => (typeof spot === 'string' ? parseInt(spot) + carry : spot + carry),
      0,
    )
  }

  public get bnImpressions() {
    return new BigNumber(this.impressions)
  }

  public get impressions() {
    return this.line_items.reduce(
      (carry, item) =>
        (typeof item.impressions === 'string'
          ? parseInt(item.impressions) + carry
          : item.impressions + carry),
      0,
    )
  }

  public get gross_rate() {
    return this.gross_cost ? +new BigNumber(this.gross_cost).div(this.impressions).times(1000) : 0
  }

  public get net_rate() {
    return this.net_cost ? +new BigNumber(this.net_cost).div(this.impressions).times(1000) : 0
  }

  public get gross_cost() {
    return this.line_items.reduce(
      (carry, item) =>
        (typeof item.gross_cost === 'string'
          ? Number(item.gross_cost) + carry
          : item.gross_cost + carry),
      0,
    )
  }

  public get net_cost() {
    return this.line_items.reduce(
      (carry, item) =>
        (typeof item.net_cost === 'string' ? Number(item.net_cost) + carry : item.net_cost + carry),
      0,
    )
  }

  public get start_at() {
    let ret: any = null

    this.line_items.forEach((item: MediaPlanItem) => {
      if (
        (ret == null && item.start_at)
        || (ret != null && item.start_at && ret.isAfter(moment(item.start_at)))
      ) {
        ret = moment(item.start_at)
      }
    })

    return ret ? ret.format('YYYY-MM-DD') : null
  }

  public get end_at() {
    let ret: any = null

    this.line_items.forEach((item: MediaPlanItem) => {
      if (
        (ret == null && item.end_at)
        || (ret != null && item.end_at && ret.isBefore(moment(item.end_at)))
      ) {
        ret = moment(item.end_at)
      }
    })

    return ret ? ret.format('YYYY-MM-DD') : null
  }

  public get formType() {
    return this.type
  }

  public set formType(type: string) {
    this.type = type
    if (type !== 'default' && this.agency_commission == 0 && !this.id) {
      this.formAgencyCommission = 15
      this.metadata.view_columns = ['model', 'net_rate', 'gross_rate', 'net_cost', 'gross_cost', 'grps']
      this.metadata.billing_cycle = 'broadcast_month'
    } else if (type === 'default' && !this.id && this.agency_commission == 15) {
      this.formAgencyCommission = 0
      this.metadata.view_columns = ['model', 'net_rate', 'net_cost']
      this.metadata.billing_cycle = 'monthly'
    }

    this.line_items.forEach(i => {
      i.type = this.type
    })
  }

  public get formStatus() {
    return startCase(this.status.replaceAll('_', ' '))
  }

  public get status_color(): string {
    if (this.status === 'draft') {
      return 'secondary'
    }
    if (this.status === 'confirmed') {
      return 'success'
    }
    if (['cancelled', 'lost'].includes(this.status)) {
      return 'danger'
    }
    return 'warning'
  }

  public get formTermsId() {
    return this.terms_id
  }

  public set formTermsId(value: any) {
    if (value != this.terms_id) {
      if (value) {
        Terms.get(value).then(t => {
          if (t instanceof Terms) this.terms = t.terms
        })
      } else this.terms = ''

      this.terms_id = value
    }
  }

  private _formSchedule: any = null

  public get formSchedule() {
    if (!this._formSchedule) {
      this._formSchedule = this.line_items.map(line_item => {
        let week = this.lineItemSpotIndex(line_item.start_at, 0)

        let start = moment(line_item.start_at)
        let end = moment(line_item.end_at)
        if (this.isLinear) {
          let pointer = start.clone()
          let itemEnd = end.clone()
          let lockStart = false
          while (pointer.isSameOrBefore(itemEnd)) {
            if (line_item.metadata.days.includes(pointer.format('dddd').toLowerCase())) {
              if (!lockStart) {
                lockStart = true
                start = pointer.clone()
              }
              end = pointer.clone()
            }

            pointer.add(1, 'day')
          }
        }

        let name = `${line_item.name}: ${start.format('MM/DD')} - ${end.format('MM/DD')}`
        if (line_item.metadata.program_name) {
          name = `${line_item.metadata.program_name} (Week ${week}): ${start.format(
            'MM/DD',
          )} - ${end.format('MM/DD')}`
        }

        return {
          code: `${String(this.number).padStart(String(this.number).length + 1, '0')}-${String(
            line_item.number,
          ).padStart(3, '0')}`,
          name,
          start_at: start.format('YYYY-MM-DD'),
          end_at: end.format('YYYY-MM-DD'),
          days: line_item.metadata.days
            .map((day: string) => day.slice(0, 1).toUpperCase() + day.slice(1, 3))
            .join(', '),
          impressions: line_item.impressions,
          net_rate: line_item.net_rate,
          notes: line_item.notes,
        }
      })
    }

    return this._formSchedule
  }

  public get formTerms() {
    return this.terms
  }

  public set formTerms(value: string | null) {
    if (value != this.terms) {
      this.terms = value
      this.terms_id = 'custom'
    }
  }

  public get isLinear() {
    return ['media_ocean', 'strata'].includes(this.type)
  }

  constructor(source: any = {}) {
    super()

    // Remove dynamic data
    if (source.start_at) delete source.start_at
    if (source.end_at) delete source.end_at
    if (source.impressions) delete source.impressions

    Object.assign(this, source)

    if (source.agency) {
      this.agency = Company.toObject(source.agency)
    }

    if (source.station) {
      this.station = Company.toObject(source.station)
    }

    if (source.advertiser) {
      this.advertiser = Company.toObject(source.advertiser)
    }

    if (source.sales_management) {
      this.sales_management = User.toObject(source.sales_management)
    }

    if (source.sales_rep) {
      this.sales_rep = User.toObject(source.sales_rep)
    }

    if (source.account_manager) {
      this.account_manager = User.toObject(source.account_manager)
    }

    if (source.line_items) {
      this.line_items = MediaPlanItem.toObjectList(source.line_items).map(i => {
        i.type = this.type
        i.agency_commission = this.agency_commission
        i._showDetails = false
        return i
      })
    }

    return this
  }

  public toObject(source: any) {
    let instance = this.clone()

    // Remove dynamic data
    if (source.start_at !== undefined) delete source.start_at
    if (source.end_at !== undefined) delete source.end_at
    if (source.impressions !== undefined) delete source.impressions

    Object.assign(instance, source)

    if (source.agency) {
      instance.agency = Company.toObject(source.agency)
    }

    if (source.station) {
      instance.station = Company.toObject(source.station)
    }

    if (source.advertiser) {
      instance.advertiser = Company.toObject(source.advertiser)
    }

    if (source.sales_management) {
      instance.sales_management = User.toObject(source.sales_management)
    }

    if (source.sales_rep) {
      instance.sales_rep = User.toObject(source.sales_rep)
    }

    if (source.account_manager) {
      instance.account_manager = User.toObject(source.account_manager)
    }

    if (source.line_items) {
      instance.line_items = MediaPlanItem.toObjectList(source.line_items)
        .map(i => {
          i.type = instance.type
          i.agency_commission = instance.agency_commission
          i._showDetails = false
          return i
        })
        .sort((a, b) => a.number - b.number)
    }

    const base = new MediaPlan()
    if (source.metadata) {
      instance.metadata = { ...base.metadata, ...source.metadata }
    } else {
      instance.metadata = { ...base.metadata }
    }

    return instance
  }

  public addLineItem() {
    const item = MediaPlanItem.toObject({
      number: this.line_items.length + 1,
      agency_commission: this.agency_commission,
      type: this.type,
      start_at: moment(this.end_at)
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      end_at: moment(this.end_at)
        .add(1, 'week')
        .format('YYYY-MM-DD'),
    })
    if (this.metadata.station.call_letters.length === 4) {
      const code = this.metadata.station.call_letters.slice(-2)
      const dma = dmas.find(dma => dma.media_ocean_station === code)
      if (dma) item.metadata.targetting.include.dmas = [dma.id]
    }

    this.line_items.push(item)

    return item
  }

  public removeLineItem(number: number) {
    const items = this.line_items.filter(i => i.number != number)

    let count = 1
    items.forEach(i => {
      i.number = count++
    })
    this.line_items = items
  }

  public addDemoTarget() {
    this.metadata.demo_targets.push({
      target: 'AD',
      age_low: '25',
      age_high: '54',
    })
  }

  public removeDemoTarget(index: number) {
    this.metadata.demo_targets.splice(index, 1)
  }

  public loadOrderData(order_data: any) {
    if (!order_data.order_cash && !order_data.order_trade) return this

    let base_type = order_data.order_cash ? 'order_cash' : 'order_trade'
    let order = order_data[base_type]

    // Assign Order data
    if (order.agency_name) {
      this.metadata.agency.name = order.agency_name
    }
    if (order.idb) {
      this.metadata.agency.id = order.idb
    }
    if (order.agency_address) {
      if (Array.isArray(order.agency_address)) {
        for (let i = 0; i < order.agency_address.length && i <= 3; i++) {
          this.metadata.agency[`address_line_${i + 1}`] = order.agency_address[i]
        }
      } else {
        this.metadata.agency.address_line_1 = order.agency_address
      }
    }
    if (order.client_name) {
      this.metadata.header.advertiser_name = order.client_name
    }
    if (order.product_name) {
      this.metadata.header.product_name = order.product_name
    }
    if (order.estimate_code) {
      this.metadata.header.agency_estimate_code = order.estimate_code
    }
    if (order.product_code) {
      this.metadata.header.agency_product_code = order.product_code
    }
    if (order.client_code) {
      this.metadata.header.agency_advertiser_code = order.client_code
    }
    if (order.buyer_name) {
      this.metadata.header.representative = order.buyer_name
    }
    if (order.start) {
      this.metadata.header.start_date = moment(order.start).format('YYMMDD')
    } else {
      this.metadata.header.start_date = moment(this.start_at).format('YYMMDD')
    }
    if (order.end) {
      this.metadata.header.end_date = moment(order.end).format('YYMMDD')
    } else {
      this.metadata.header.end_date = moment(this.end_at).format('YYMMDD')
    }
    if (order.invoice_mode) {
      this.metadata.invoice_mode = order.invoice_mode
    } else {
      this.metadata.invoice_mode = 'platform'
    }

    if (base_type === 'order_cash') {
      this.metadata.cash_io = order_data.order_cash.file
      this.metadata.header.station_order_number = order_data.order_cash.order_number
      if (order_data.order_trade) {
        this.metadata.header.station_trade_order_number = order_data.order_trade.order_number
        this.metadata.trade_io = order_data.order_trade.file
      }
    } else {
      this.metadata.header.station_trade_order_number = order_data.order_trade.order_number
    }

    return this
  }

  public async export(type: string, data: any = {}) {
    if (!this.id) {
      return Promise.reject(new Error('Media plan must be saved before it can be downloaded.'))
    }
    return MediaPlan.export([this.id], type, data)
  }

  public static async export(media_plans: string[], type: string, data: any = {}) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.post(`media_plans/export/${type}`, { media_plans, ...data, instance_id })
  }

  public static async uploadOrderFiles(order_cash: any, order_trade: any) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.form('media_plans/upload-order-files', {
      order_cash,
      order_trade,
      instance_id,
    })
  }

  public static async processOrderFiles(order_cash: any, order_trade: any) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.get('media_plans/process-order-files', {
      order_cash,
      order_trade,
      instance_id,
    })
  }

  public async getOpportunityData() {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api.get(`media_plan/opportunity/${this.salesforce_opportunity_id}`)
  }

  public async cancel(lost: boolean = false) {
    this.status = lost ? 'lost' : 'cancelled'
    return this.save()
  }

  public static buildVideoCopyId(media_plan: MediaPlan, line_item: MediaPlanItem) {
    let demo = media_plan.metadata.demo_targets[line_item.metadata.demo_target]

    return `I${demo.target}${demo.age_low}${demo.age_high}=${(line_item.impressions / 100)
      .toFixed(0)
      .replaceAll('.', '')}`
  }

  private lineItemSpotIndex(start_at: string, index: number): number {
    let pointer = moment(this.start_at)
      .startOf('week')
      .add(index, 'weeks')
      .add(1, 'day')
    let start = moment(start_at)
      .startOf('week')
      .add(1, 'day')

    if (index > 0) {
      start
        .add(index, 'weeks')
        .startOf('week')
        .add(1, 'day')
    }

    let ret: number = 1 + index

    while (pointer.isBefore(start)) {
      pointer.add(1, 'weeks')
      ret++
    }

    return ret
  }

  public static tableFields: any = tableFields
}
