







































































































import { Prop, Component } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import MediaPlan from '@/models/MediaPlan'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import WebMessage from '@/models/WebMessage'
import SelectOption from '@/models/interface/SelectOption'
import { MediaPlanProposalActions } from '@/models/interface/Common'

@Component({
  components: {
    SelectPicker,
  },
})
export default class ExportMediaPlan extends ViewModel {
  @Prop({ required: true })
  public media_plans!: MediaPlan[]

  private showModal = true

  public has_missing_io_files: boolean = false

  public appendix: boolean = false

  public io_files: string[] = ['system']

  public map_mode: string = 'dma'

  public proposal_action: string = 'New'

  public export_type: string = 'excel'

  public logos: string[] = []

  public slides: string[] = [
    // 'cover', // On by default
    'overview',
    'details',
    'distribution',
    'placements',
    // 'outro', // On by default
  ]

  private slide_options = [
    new SelectOption('Overview', 'overview'),
    new SelectOption('Items Details', 'details'),
    new SelectOption('Distribution', 'distribution'),
    new SelectOption('Media Placement', 'placements'),
  ]

  private map_options = [new SelectOption('DMA', 'dma'), new SelectOption('State', 'state')]

  private get selected_ids() {
    return this.media_plans.map(m => (typeof m.id === 'string' ? m.id : ''))
  }

  public get io_file_options() {
    this.has_missing_io_files = false
    let ret = [{ text: 'Insertion Order', value: 'system' }]

    let has_cash_file = false
    let has_trade_file = false

    this.media_plans.forEach((m: MediaPlan) => {
      if (m.metadata.cash_io) has_cash_file = true
      if (m.metadata.trade_io) has_trade_file = true
    })

    if (has_cash_file) {
      ret.push({ text: 'Order Confirmation', value: 'cash' })
      this.has_missing_io_files = this.media_plans.some((m: MediaPlan) => !m.metadata.cash_io)
    }

    if (has_trade_file) {
      ret.push({ text: 'Trade Order Confirmation', value: 'trade' })
      if (!this.has_missing_io_files) {
        this.has_missing_io_files = this.media_plans.some((m: MediaPlan) => !m.metadata.trade_io)
      }
    }

    return ret
  }

  public get export_options() {
    this.has_missing_io_files = false

    let has_linear = this.media_plans.some((m: MediaPlan) => m.isLinear)
    let ret = []
    if (has_linear) {
      ret.push(new SelectOption('Proposal XML', 'xml'))
      this.has_missing_io_files = this.media_plans.some((m: MediaPlan) => !m.isLinear)
    }
    ret.push(
      new SelectOption('Media Plan (Excel)', 'excel'),
      new SelectOption('Insertion Order / Plan (PDF)', 'io'),
      new SelectOption('Delivery Report (PowerPoint)', 'delivery_report'),
      new SelectOption('Media Plan Snapshot (Excel)', 'snapshot'),
    )
    return ret
  }

  public get proposalOptions() {
    return MediaPlanProposalActions
  }

  public confirm() {
    MediaPlan.export(this.selected_ids, this.export_type, {
      proposal_action: this.proposal_action,
      slides: this.slides,
      logos: this.logos,
      appendix: this.appendix,
      map: this.map_mode,
      io_files: this.io_files,
    }).then(() => {
      WebMessage.success('Generating Files, do not close this window!')
    })
  }

  private logo_options = [
    {
      name: 'CBS Sports Network',
      value: 'CBS Sports Network',
    },
    {
      name: 'ESPN',
      value: 'ESPN',
    },
    {
      name: 'ESPN 2',
      value: 'ESPN 2',
    },
    {
      name: 'ESPN 3',
      value: 'ESPN 3',
    },
    {
      name: 'ESPN News',
      value: 'ESPN News',
    },
    {
      name: 'Eleven Sports',
      value: 'Eleven Sports',
    },
    {
      name: 'FS1',
      value: 'FS1',
    },
    {
      name: 'FS2',
      value: 'FS2',
    },
    {
      name: 'Fox Sports Network',
      value: 'Fox Sports Network',
    },
    {
      name: 'MLB',
      value: 'MLB',
    },
    {
      name: 'MLB Network',
      value: 'MLB Network',
    },
    {
      name: 'MLS (Soccer)',
      value: 'MLS (Soccer)',
    },
    {
      name: 'NBA',
      value: 'NBA',
    },
    {
      name: 'NBC Sports',
      value: 'NBC Sports',
    },
    {
      name: 'NCAA - ACC Network',
      value: 'NCAA - ACC Network',
    },
    {
      name: 'NCAA - Big 10 Network',
      value: 'NCAA - Big 10 Network',
    },
    {
      name: 'NCAA - CBS Sports Network',
      value: 'NCAA - CBS Sports Network',
    },
    {
      name: 'NCAA - Longhorn Network',
      value: 'NCAA - Longhorn Network',
    },
    {
      name: 'NCAA - Pac 12',
      value: 'NCAA - Pac 12',
    },
    {
      name: 'NCAA - SEC',
      value: 'NCAA - SEC',
    },
    {
      name: 'NFL',
      value: 'NFL',
    },
    {
      name: 'NFL Network',
      value: 'NFL Network',
    },
    {
      name: 'NHL',
      value: 'NHL',
    },
    {
      name: 'Outdoor Network',
      value: 'Outdoor Network',
    },
    {
      name: 'Outside TV',
      value: 'Outside TV',
    },
    {
      name: 'PGA - Golf Channel',
      value: 'PGA - Golf Channel',
    },
    {
      name: 'Racing America',
      value: 'Racing America',
    },
    {
      name: 'SpartanTV',
      value: 'SpartanTV',
    },
    {
      name: 'Stadium',
      value: 'Stadium',
    },
    {
      name: 'TVG',
      value: 'TVG',
    },
    {
      name: 'TVG2',
      value: 'TVG2',
    },
    {
      name: 'Tennis Channel',
      value: 'Tennis Channel',
    },

    // Legacy
    {
      name: 'BeinSports',
      value: 'BeinSports',
    },
    {
      name: 'Sportsman',
      value: 'Sportsman',
    },
    {
      name: 'ESPN U',
      value: 'ESPN U',
    },
    {
      name: 'World Fishing Network',
      value: 'World Fishing Network',
    },
  ]
}
