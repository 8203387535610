














































import { Prop, Component } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import MediaPlan from '@/models/MediaPlan'
import router from '@/Routes'

@Component({
  components: {},
})
export default class ConfirmMediaPlan extends ViewModel {
  @Prop({ required: true })
  public media_plan!: MediaPlan

  @Prop({ required: true })
  public dataTable!: any

  private showModal = true

  public order_cash: any = null

  public order_trade: any = null

  public cancel_type: string = 'cancel'

  public confirm() {
    let ref = window.location.href.includes('my-dashboard') ? '&ref=MyDashboard' : ''

    if (!this.order_cash && !this.order_trade) {
      router.push(`/app/sales/media_plan/${this.media_plan.id}?confirm${ref}`)
    } else {
      MediaPlan.uploadOrderFiles(this.order_cash, this.order_trade).then((response: any) => {
        let order_files = response.data.result.order_cash
          ? `&order_cash=${response.data.result.order_cash}`
          : ''
        order_files += response.data.result.order_trade
          ? `&order_trade=${response.data.result.order_trade}`
          : ''
        router.push(`/app/sales/media_plan/${this.media_plan.id}?confirm${ref}${order_files}`)
      })
    }
  }
}
