import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import router from '@/Routes'
import Vue from 'vue'
import CancelMediaPlan from '@/pages/Sales/MediaPlan/components/CancelMediaPlan.vue'
import ConfirmMediaPlan from '@/pages/Sales/MediaPlan/components/ConfirmMediaPlan.vue'
import ExportMediaPlan from '@/pages/Sales/MediaPlan/components/ExportMediaPlan.vue'
import { startCase } from 'lodash'
import MediaPlan from '../MediaPlan'
import WebMessage from '../WebMessage'

const { user } = getModule(SystemtModule)

export const model_name = 'media_plan'

export const tableFields: any = [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
  },
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'number',
    label: '#',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    show: false,
    type: 'capitalize',
    tdClass: 'align-middle text-center',
    formatter: (value: string | null) => (value ? startCase(value.replaceAll('_', ' ')) : 'N/A'),
  },
  {
    key: 'agency_id',
    label: 'Agency',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'agency',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'station_id',
    label: 'Station',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'station',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'advertiser_id',
    label: 'Advertiser',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'advertiser',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'sales_management',
    label: 'SM',
    sortable: true,
    show: false,
    type: 'object',
    object: {
      key: 'sales_management',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'sales_rep',
    label: 'AE',
    sortable: true,
    show: false,
    type: 'object',
    object: {
      key: 'sales_rep',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'account_manager',
    label: 'AM',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'account_manager',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'formStatus',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    color: (media_plan: MediaPlan) => media_plan.status_color,
    formatter: (value: any, key: string, media_plan: MediaPlan) => media_plan.formStatus,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    show: true,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    show: true,
    type: 'action_list',
    actions: [
      {
        icon: 'search',
        title: 'Show Details',
        event: 'details',
      },
      {
        icon: 'file-invoice',
        title: 'View Plan',
        event: 'view-schedule',
        action: (media_plan: MediaPlan) => {
          let ref = window.location.href.includes('my-dashboard') ? '&ref=MyDashboard' : ''
          router.push(`/app/sales/media_plan/${media_plan.id}/view${ref}`)
        },
      },
      {
        icon: 'calendar',
        title: 'View Schedule',
        event: 'view',
        action: (media_plan: MediaPlan) => {
          let ref = window.location.href.includes('my-dashboard') ? '&ref=MyDashboard' : ''
          router.push(`/app/sales/media_plan/${media_plan.id}/view/schedule${ref}`)
        },
      },
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        show: () => user && user.can('media_plan', 'edit'),
        action: (media_plan: MediaPlan) => {
          let ref = window.location.href.includes('my-dashboard') ? '&ref=MyDashboard' : ''
          router.push(`/app/sales/media_plan/${media_plan.id}${ref}`)
        },
      },
      {
        icon: 'copy',
        title: 'Clone',
        event: 'clone',
        show: () => user && user.can('media_plan', 'edit'),
        action: (media_plan: MediaPlan) => {
          let ref = window.location.href.includes('my-dashboard') ? '&ref=MyDashboard' : ''
          router.push(`/app/sales/media_plan/?from=${media_plan.id}${ref}`)
        },
      },
      {
        icon: 'file',
        title: 'Confirm Order',
        event: 'createIO',
        show: (media_plan: MediaPlan) =>
          user
          && user.can('media_plan', 'edit')
          && !['cancelled', 'lost'].includes(media_plan.status),
        action: (media_plan: MediaPlan) => {
          let Component = Vue.extend(ConfirmMediaPlan)
          let instance = new Component({
            propsData: {
              media_plan,
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'download',
        title: 'Export',
        event: 'exportXML',
        action: (media_plan: MediaPlan, dataTable: any) => {
          let Component = Vue.extend(ExportMediaPlan)
          let instance = new Component({
            propsData: {
              media_plans: [media_plan],
              mode: 'single',
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'times',
        title: 'Cancel',
        event: 'cancel',
        show: (media_plan: MediaPlan) =>
          user
          && user.can('media_plan', 'delete')
          && !media_plan.metrics.impressions
          && !['cancelled', 'lost', 'confirmed'].includes(media_plan.status),
        action: (media_plan: MediaPlan, dataTable: any) => {
          let Component = Vue.extend(CancelMediaPlan)
          let instance = new Component({
            propsData: {
              media_plan,
              dataTable,
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        show: (media_plan: MediaPlan) =>
          user && user.can('media_plan', 'delete') && !media_plan.metrics.impressions,
        action: (media_plan: MediaPlan, dataTable: any) =>
          WebMessage.confirm(
            `Are you sure that you want to delete the Media Plan "<strong>${media_plan.name}</strong>"? You won't be able to restore it!`,
            'Delete Media Plan',
          ).then((value: boolean) => {
            if (value) {
              media_plan.delete().then(() => {
                dataTable.refresh()
              })
            }
          }),
      },
    ],
  },
]
